<template>
  <v-container>
    <v-radio-group
      v-model="radio"
      row
      class="mt-0"
    >
      <v-row>
        <v-col cols="6">
          <v-radio
            :label="entidade === 'P' ? 'Notificar Produtor' : 'Notificar Técnico'"
            value="selecionar"
            @click="todos = false"
          />
        </v-col>
        <v-col cols="6">
          <v-radio
            label="Notificar todos"
            value="todos"
            @click="limparSelecao"
          />
        </v-col>
      </v-row>
    </v-radio-group>

    <v-autocomplete
      v-model="idSelecionado"
      :items="dataAutocomplete"
      :label="entidade === 'P' ? 'Selecione o Produtor' : 'Selecione o Técnico'"
      :disabled="todos"
      :error-messages="mensagemErro.select"
      item-value="id"
      item-text="nome"
      validate-on-blur
      class="d-flex justify-space-between"
      dense
      outlined
      clearable
      rounded
      multiple
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          dense
          small
          class="mt-1"
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item ? data.item.nome.length > 25 ? `${data.item.nome.slice(0, 25)}...` : data.item.nome : '' }}
        </v-chip>
      </template>
    </v-autocomplete>
    <v-text-field
      v-model="data_noti.assunto"
      :error-messages="mensagemErro.assunto"
      label="Assunto"
      outlined
      rounded
      dense
    />
    <v-textarea
      v-model="data_noti.mensagem"
      :error-messages="mensagemErro.mensagem"
      label="Mensagem"
      height="120"
      counter="255"
      maxlength="255"
      outlined
      rounded
      dense
    />
    <v-row
      class="pa-3"
      justify="center"
      justify-sm="end"
    >
      <v-btn
        color="error"

        rounded
        class="text-none mr-3 text-white"
        @click="CLOSE"
      >
        <v-icon
          dark
          left
        >
          mdi-minus-circle
        </v-icon>
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        rounded
        class="text-none text-white"
        @click="enviar"
      >
        <v-icon
          dark
          left
        >
          mdi-send-check
        </v-icon>
        Enviar
      </v-btn>
    </v-row>
  </v-container>
</template>
    <script>
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { CLOSE, NOTIFICA, SUBMIT } from '@/store/modules/notificacoes'
  export default {
    name: 'NotificacaoProdutorDialog',
    props: {
      entidade: {
        type: String,
        default: 'P',
      },
    },
    data () {
      return {
        todos: false,
        radio: 'selecionar',
        valid: false,
        mensagemErro: {
          select: '',
          assunto: '',
          mensagem: '',
        },
        idSelecionado: [],

      }
    },
    computed: {
      ...mapState('notificacoes', ['dialog', 'data_noti', 'entidade_produtor', 'entidade_tecnico']),
      dataAutocomplete () {
        if (this.entidade === 'P') {
          return this.entidade_produtor
        } else {
          return this.entidade_tecnico
        }
      },
    },
    methods: {
      ...mapMutations('notificacoes', [CLOSE]),
      ...mapActions('notificacoes', [NOTIFICA, SUBMIT]),
      remove (item) {
        this.idSelecionado = this.idSelecionado.filter((i) => i !== item.id)
      },
      limparSelecao () {
        this.idSelecionado = []
        this.todos = true
      },
      validarCampos () {
        if (this.radio === 'selecionar') {
          if (this.idSelecionado.length > 0) {
            this.valid = true
          } else {
            this.valid = false
            this.mensagemErro.select = 'Selecine uma pessoa'
          }
        } else {
          this.valid = true
          this.mensagemErro.select = ''
        }
        if (this.data_noti.assunto.trim() === '') {
          this.valid = false
          this.mensagemErro.assunto = 'Campo obrigatório'
        } else {
          this.mensagemErro.assunto = ''
        }
        if (this.data_noti.mensagem.trim() === '') {
          this.valid = false
          this.mensagemErro.mensagem = 'Campo obrigatório'
        } else {
          this.mensagemErro.mensagem = ''
        }
      },
      enviar () {
        this.validarCampos()
        if (this.valid) {
          if (this.radio === 'selecionar') {
            this.data_noti.tipo = 1
            this.data_noti.nome = this.entidade === 'P' ? 'produtor' : 'tecnico'
            this.data_noti.id = this.filtraDados(this.idSelecionado)
            this.SUBMIT()
          } else {
            this.data_noti.tipo = this.entidade === 'P' ? 7 : 8
            this.NOTIFICA()
          }
        }
      },
      filtraDados (item) {
        return this.dataAutocomplete.filter((i) => item.includes(i.id))
      },
    },
  }
    </script>
