<template>
  <div class="content">
    <template>
      <div>
        <v-card class="mt-0 pa-3">
          <base-loading
            v-if="loading"
            base-class="fill-height ma-0 flex-column"
          />
          <div v-else>
            <v-container
              fluid
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    outlined
                    hide-details
                    rounded
                    dense
                  />
                </v-col>
                <v-col cols="auto">
                  <data-field
                    v-model="data_inicial"
                    :label="'Data Inicial'"
                    :data-max="data_final"
                    @set="handleDataFilter()"
                  />
                </v-col>
                <v-col cols="auto">
                  <data-field
                    v-model="data_final"
                    :label="'Data Final'"
                    :data-min="data_inicial"
                    @set="handleDataFilter()"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-btn-toggle
                    v-model="btnSelected"
                    borderless
                    rounded
                    dense
                    mandatory
                    color="primary"
                    class="testeStyle"
                  >
                    <v-btn
                      depressed
                      value="all"
                      :small="$vuetify.breakpoint.name === 'xs' ? true : false"
                      class="text-none"
                    >
                      Todas
                    </v-btn>
                    <v-btn
                      depressed
                      value="0"
                      class="text-none"
                      :small="$vuetify.breakpoint.name === 'xs' ? true : false"
                    >
                      Não Lidas
                    </v-btn>
                    <v-btn
                      depressed
                      value="semana"
                      class="text-none"
                      :small="$vuetify.breakpoint.name === 'xs' ? true : false"
                    >
                      Última Semana
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col
                  cols="auto"
                >
                  <v-btn
                    v-if="(this.$store.state.user.roles[0].slug === 'gestor' || this.$store.state.user.roles[0].slug === 'supervisor' || this.$store.state.user.roles[0].slug === 'admin') && havePermission()"
                    color="primary"
                    rounded
                    class="text-none"
                    style="justify-content: center; align-items: center;"
                    @click="OPEN"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                    Nova mensagem
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-row class="ma-2">
              <v-col
                cols="12"
                lg="7"
              >
                <v-card
                  class="mx-1 mt-2"
                  elevation="1"
                  outlined
                >
                  <v-data-table
                    style="cursor: pointer;"
                    :height="$vuetify.breakpoint.lgAndUp ? '60vh' : 'calc(81vh - 295px)'"
                    :headers="mostraCabecalho"
                    :items="dadosTabela()"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    :search="search"
                    fixed-header
                    hide-default-footer
                    :sort-by="['data.date']"
                    :sort-desc="true"
                    @page-count="pageCount = $event"
                    @click:row="exibirDetalhes"
                  >
                    <template v-slot:[`item.situacao`]="{ item }">
                      <v-icon>
                        {{ item.situacao === 1 ? 'mdi-email-open' : 'mdi-email' }}
                      </v-icon>
                    </template>
                    <template v-slot:[`item.date_leitura`]="{ item }">
                      <span>
                        {{ item.date_leitura ? moment( item.date_leitura ).format( "DD/MM/YYYY") : '' }}
                      </span>
                    </template>
                    <template v-slot:[`item.notification_lida`]="{ item }">
                      {{ item.notification_lida ? moment( item.date_leitura ).format( "DD/MM/YYYY") : '' }}
                    </template>
                    <template v-slot:[`item.data.date`]="{ item }">
                      <span>
                        {{ moment( item.data.date ).format( "DD/MM/YYYY") }}
                      </span>
                    </template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-row justify="center">
                      <v-col
                        cols="6"
                        class="d-flex justify-space-between align-center"
                      >
                        <v-btn
                          icon
                          depressed
                          color=""
                          @click="page > 1 && page--"
                        >
                          <v-icon size="30">
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <div
                          class="d-flex align-center"
                          style="background-color: white; border-bottom: 1px solid rgba(0,0,0,.12);"
                        >
                          <v-text-field
                            v-model="page"
                            dense
                            hide-details="auto"
                            class="centered-input"
                            style="max-width: 40px; text-align: right; border-bottom: none !important;"
                            @input="page = parseInt(page, 10) || 1"
                          />
                          <span style="min-width: 60px; text-align: center; padding-top: 7px;"> / {{ pageCount }}</span>
                        </div>
                        <v-btn
                          icon
                          depressed
                          @click="page < pageCount && page++"
                        >
                          <v-icon size="30">
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                lg="5"
              >
                <v-card
                  v-if="dialog"
                  class="mt-0"
                  :height="$vuetify.breakpoint.lgAndUp ? '66vh' : '60vh'"
                >
                  <v-card-title>
                    Nova mensagem
                  </v-card-title>
                  <notificacao-envio
                    :entidade="tipo"
                  />
                </v-card>
                <v-card
                  v-else
                  class="mt-0"
                  :height="$vuetify.breakpoint.lgAndUp ? '66vh' : '60vh'"
                >
                  <v-card-title
                    class="pb-3"
                  >
                    Detalhes da mensagem
                  </v-card-title>
                  <v-card-text v-if="detalhes">
                    <div v-if="this.$store.state.user.roles[0].slug === 'gestor' || this.$store.state.user.roles[0].slug === 'admin' || this.$store.state.user.roles[0].slug === 'supervisor'">
                      <p>
                        Para {{ tipo === 'P' ? 'produtor:' : 'técnico:' }} <span style="font-weight: 500;">{{ tipo === 'P' ? detalhes.produtor.nome : detalhes.tecnico.nome }}</span>
                      </p>
                      <div>
                        <p>
                          Assunto: <span
                            span
                            style="font-weight: 500;"
                          >{{ detalhes.data.title }}</span>
                        </p>
                      </div>
                      <div style="border: 1px solid lightgray; border-radius: 10px; padding: 10px; min-height: 100px;">
                        {{ detalhes.mensagem }}
                      </div>
                      <v-row
                        class="mt-3"
                        dense
                      >
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            :value="formatDate(detalhes.data.date)"
                            label="Envio"
                            outlined
                            dense
                            readonly
                            rounded
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            :value="formatDate(detalhes.date_leitura)"
                            label="Leitura"
                            outlined
                            dense
                            readonly
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <p>
                        Para: <span style="font-weight: 500;">Você</span>
                      </p>
                      <div>
                        <p>
                          Assunto: <span
                            span
                            style="font-weight: 500;"
                          >{{ detalhes.data.title }}</span>
                        </p>
                      </div>
                      <div style="border: 1px solid lightgray; border-radius: 10px; padding: 10px; min-height: 100px;">
                        {{ detalhes.mensagem }}
                      </div>
                      <v-row
                        class="mt-3"
                        dense
                      >
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            :value="formatDate(detalhes.data.date)"
                            label="Envio"
                            outlined
                            dense
                            readonly
                            rounded
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            :value="formatDate(detalhes.date_leitura)"
                            label="Leitura"
                            outlined
                            dense
                            readonly
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-card-text
                    v-else
                    style="display: flex; justify-content: center; padding: 50px;"
                  >
                    <p>Selecione uma mensagem para ver os detalhes</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </template>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import NotificacoesStore, { BOOTSTRAP, OPEN, LEITURA_NOTIFICACAO } from '@/store/modules/notificacoes'
  import moment from 'moment'
  import NotificacaoEnvio from './NotificacaoEnvio.vue'

  export default {
    components: { NotificacaoEnvio },
    props: {
      tipo: {
        type: String,
        default: 'P',
      },
      id: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        btnSelected: 'all',
        permission: '',
        search: '',
        data_inicial: 'from',
        data_final: 'to',
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        headers1: [
          { text: 'Status', value: 'situacao', width: '95px', align: 'center' },
          { text: 'Envio', value: 'data.date', width: '95px', align: 'center' },
          { text: 'Leitura', value: 'date_leitura', width: '110px', align: 'center' },
          { text: 'Nome do Produtor', value: 'produtor.nome' },
          { text: 'Título da mensagem', value: 'data.title' },
        ],
        headers2: [
          { text: 'Status', value: 'situacao', width: '95px', align: 'center' },
          { text: 'Envio', value: 'data.date', width: '95px', align: 'center' },
          { text: 'Leitura', value: 'date_leitura', width: '110px', align: 'center' },
          { text: 'Nome do Técnico', value: 'tecnico.nome' },
          { text: 'Título da mensagem', value: 'data.title' },
        ],
        headers3: [
          { text: 'Status', value: 'situacao', width: '95px', align: 'center' },
          { text: 'Envio', value: 'data.date', width: '95px', align: 'center' },
          { text: 'Leitura', value: 'date_leitura', width: '110px', align: 'center' },
          { text: 'Título da mensagem', value: 'data.title' },
        ],
      }
    },
    computed: {
      ...mapState('notificacoes', ['loading', 'dialog', 'detalhes', 'notificaProdutor', 'notificaTecnico']),
      ...mapGetters(['hasPermission']),
      detailsSize () {
        return this.$vuetify.breakpoint.xl ? 'calc(81vh - 220px)' : 'calc(81vh - 295px)'
      },
      mostraCabecalho () {
        if (this.$store.state.user.roles[0].slug === 'gestor' ||
          this.$store.state.user.roles[0].slug === 'admin' ||
          this.$store.state.user.roles[0].slug === 'supervisor'
        ) {
          return this.tipo === 'P' ? this.headers1 : this.headers2
        }
        return this.headers3
      },
    },
    watch: {
      tipo: function () {
        this.page = 1
        this.btnSelected = 'all'
        if (this.permissionTotal()) {
          this.$router.push({
            path: '/controleacesso/sempermissao',
          })
        }
        this.BOOTSTRAP({ data_inicial: this.data_inicial, data_final: this.data_final, tipo_pessoa: this.tipo })
      },
    },
    created () {
      if (this.permissionTotal()) {
        this.$router.push({
          path: '/controleacesso/sempermissao',
        })
      }
      if (!this.$store.hasModule('notificacoes')) { this.$store.registerModule('notificacoes', NotificacoesStore) }

      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 1)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = data_i
      this.data_final = data_f
      if (this.$store.state.user.roles[0].slug === 'produtor' || this.$store.state.user.roles[0].slug === 'tecnico') {
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f, tipo_pessoa: this.tipo, id: this.$store.state.user.id })
      } else {
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f, tipo_pessoa: this.tipo })
      }
    },
    methods: {
      ...mapActions('notificacoes', [BOOTSTRAP, OPEN, LEITURA_NOTIFICACAO]),
      dadosTabela () {
        let dados = []
        if (this.tipo === 'P') {
          dados = this.notificaProdutor
        } else {
          dados = this.notificaTecnico
        }
        const dateFim = moment().subtract(7, 'days')
        switch (this.btnSelected) {
          case 'all':
            return dados
          case '0':
            return dados.filter((item) => {
              return item.situacao === 0
            })
          case 'semana':
            return dados.filter((item) => {
              return moment(item.data.date).isSameOrAfter(dateFim)
            })
          default:
            return dados
        }
      },
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.btnSelected = 'all'
        if (this.$store.state.user.roles[0].slug === 'produtor' || this.$store.state.user.roles[0].slug === 'tecnico') {
          this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f, tipo_pessoa: this.tipo, id: this.$store.state.user.id })
        } else {
          this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f, tipo_pessoa: this.tipo })
        }
      },
      exibirDetalhes (item) {
        this.LEITURA_NOTIFICACAO({ notificacao: item })
      },
      formatDate (data) {
        if (!data) return ''
        return moment(data).format('DD/MM/YYYY - HH:mm:ss')
      },
      havePermission () {
        if (this.$store.state.user.roles[0].slug === 'gestor' || this.$store.state.user.roles[0].slug === 'supervisor') {
          if (this.tipo === 'P') {
            return this.$store.getters.hasPermission('enviar_notificacao_produtor')
          } else if (this.tipo === 'T') {
            return this.$store.getters.hasPermission('enviar_notificacao_tecnico')
          } else {
            return true
          }
        }
        return true
      },
      permissionTotal () {
        if (this.$store.state.user.roles[0].slug === 'gestor' || this.$store.state.user.roles[0].slug === 'supervisor') {
          if (this.tipo === 'P') {
            return !this.$store.getters.hasPermission('list_notificacao_produtor')
          } else if (this.tipo === 'T') {
            return !this.$store.getters.hasPermission('list_notificacao_tecnico')
          } else {
            return false
          }
        }
        return false
      },
    },
  }
</script>
<style lang="css">

.centered-input input {
      text-align: right;
    }
</style>
